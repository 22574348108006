import React from "react"
import { Link } from "gatsby"

import { StyledAnchor, StyledAnchorLink, StyledButton } from "../style/button.style"
import {useFetch} from "../utils/useFetch";
import {jsonConfigSito} from "../utils/jsonData";


const UnstyledButton = ({ href, hrefJSON, stripHash, className, children, icon, targetBlank, formSubmit, title}) => {

    const state = useFetch(jsonConfigSito);
    // const state = {};

    return (
        <>

            { targetBlank &&

            <a href={hrefJSON ? state.data[hrefJSON] : href} target={"_blank"} rel="noopener noreferrer" style={{margin: "0", padding: "5px", whiteSpace: "nowrap"}} className={className} title={title}>

                {/*{icon && <FontAwesomeIcon icon={icon} style={{fontSize: "1.5rem", marginRight: "5px", verticalAlign: "middle"}}/> }*/}
                {children}

            </a>

            }

            {(!targetBlank && !formSubmit) &&

            <Link to={href || '/#'} title={title}>

                {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                {children}

            </Link>
            }

        </>
    )
};

const Button = ({ id, href, hrefJSON, stripHash, className, children, fullwidth, inline, small, medium, large, color, solid, pulse, icon, targetBlank, formSubmit, title, onClick}) => {

    const state = useFetch(jsonConfigSito, {});
    // const state = {};

    return (
        <>
            { formSubmit &&

            <StyledAnchor type={"submit"} id={id}  title={title} onClick={onClick}>

                <StyledButton
                    className={className}
                    fullwidth={fullwidth}
                    inline={inline}
                    small={small}
                    medium={medium}
                    large={large}
                    color={color}
                    solid={solid}
                    pulse={pulse}
                    icon={icon}
                >

                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>

                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>

                    {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                    {children}

                </StyledButton>

            </StyledAnchor>

            }

            { targetBlank &&

            <StyledAnchor id={id} href={hrefJSON ? state.data[hrefJSON] : href} target={"_blank"} rel="noopener" title={title} onClick={onClick}>

                <StyledButton
                    className={className}
                    fullwidth={fullwidth}
                    inline={inline}
                    small={small}
                    medium={medium}
                    large={large}
                    color={color}
                    solid={solid}
                    pulse={pulse}
                    icon={icon}
                >

                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>

                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>

                    {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                    {children}

                </StyledButton>

            </StyledAnchor>

            }

            {(!targetBlank && !formSubmit) &&

            <StyledAnchorLink id={id} to={href || '/#'} {...stripHash && "stripHash"} title={title} onClick={onClick}>

                <StyledButton
                    className={className}
                    fullwidth={fullwidth}
                    inline={inline}
                    small={small}
                    medium={medium}
                    large={large}
                    color={color}
                    solid={solid}
                    pulse={pulse}
                    icon={icon}
                >

                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>

                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                        {children}
                    </b>

                    {/*{icon && <FontAwesomeIcon icon={icon} /> }*/}
                    {children}

                </StyledButton>

            </StyledAnchorLink>
            }
        </>
    )

};



export {Button, UnstyledButton};