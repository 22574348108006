import styled from 'styled-components'

export const StyledFooter = styled.footer`
  padding: 15px;
  margin-top: 1rem;
  color: ${props => props.color ? props.color :  props.theme.color.white};
  background-color: ${props => props.background ? props.background : props.theme.color.lightgray};
  text-align: center;
  letter-spacing: ${props => props.background === props.theme.color.black ? "0.03rem" : "inherit"};
  font-size: 14px;
  
  hr {
    border-bottom: 1px solid ${props => props.color ? props.color :  props.theme.color.white};
  }
`;