import React from "react"
import { Link } from "gatsby"
import { AiOutlineCloud } from "@react-icons/all-files/ai/AiOutlineCloud"
import { CgArrowLongRight} from "@react-icons/all-files/cg/CgArrowLongRight"

import { StyledCard } from "../style/card.style"
import {StaticImage} from "gatsby-plugin-image";



const Card = ({icon, className, background, children, isClickable, href, pulse, image}) => {
    console.log(image)
    return (
        <>
            {
                isClickable ?
                    <StyledCard className={className} isClickable={isClickable} image={image}>
                        <Link to={href} className="inner" background={background} >
                            {image &&
                            <div className={"card_image"}>
                            </div>}
                            <div className="inner-content">
                                {icon === "cloud" && <span className="icon" pulse={pulse}><AiOutlineCloud /></span> }
                                {children}
                                <p className="readmore px-2 mb-3 float-end"><CgArrowLongRight /></p>
                            </div>
                        </Link>
                    </StyledCard>
                    :
                    <StyledCard className={className} isClickable={isClickable} image={image}>
                        <div className="inner" background={background}>
                            {image &&
                            <div className={"card_image"}>
                            </div>}
                            <div className="inner-content">
                                {icon === "cloud" && <span className="icon" pulse={pulse}><AiOutlineCloud /></span> }
                                {children}
                            </div>
                        </div>
                    </StyledCard>

            }
        </>
    )
};

export default Card;