module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"D:/__01.LAVORO_wip/ZENIT SECURITY gatsbyjs/sito/node_modules/gatsby-remark-images","id":"7949181a-2893-598f-928c-7ece2766d8d8","name":"gatsby-remark-images","version":"6.16.0","modulePath":"D:\\__01.LAVORO_wip\\ZENIT SECURITY gatsbyjs\\sito\\node_modules\\gatsby-remark-images\\index.js","pluginOptions":{"plugins":[],"maxWidth":1035},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"D:\\__01.LAVORO_wip\\ZENIT SECURITY gatsbyjs\\sito","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1035},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zenit Security","short_name":"Zenit Security","start_url":"/","background_color":"#f7f0eb","theme_color":"#184783","theme_color_in_head":false,"display":"standalone","icon":"src/assets/icons/favicon.jpg","crossOrigin":"anonymous","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"e0645bf68fd6e814f6c8e21038f0ca85"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
