import styled, {keyframes, css} from 'styled-components'

const gradient = keyframes`
    0% {background-position:0% 50%}
    50% {background-position:100% 50%}
    100% {background-position:0% 50%}
`;

export const Wrapper = styled.div`

    ${props => props.gradient === true ? css`
    background: linear-gradient(-45deg, #48a3db, #184783, #002c60);
    background-size: 600% 600%;
    -webkit-animation: ${gradient} 30s ease infinite;
    -moz-animation: ${gradient} 30s ease infinite;
    -o-animation: ${gradient} 30s ease infinite;
    animation: ${gradient} 30s ease infinite;
    `
    :
    `
        
    `};
    
    background-color: ${props => props.theme.color.lightgray};
    background-image: linear-gradient(315deg, ${props => props.theme.color.lightgray} 0%, ${props => props.theme.color.white} 74%);
    
    box-shadow: 0px 2px 4px rgba(0,0,0,0.15), 
              0px 4px 8px rgba(0,0,0,0.15), 
              0px 8px 16px rgba(0,0,0,0.15), 
              0px 16px 32px rgba(0,0,0,0.15);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    color: ${props => props.theme.color.blue};
    position: fixed;
    top: 0;
    height: ${props => props.theme.header.height};
    width: 100%;
    transition-property: top; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    z-index: 1000; 
    
    hr {
        border-bottom: 1px solid rgba(255,255,255,0.1);
        margin-bottom: 0;
    }
    
    @media (max-width: ${props => props.theme.screen.sm}) {
        height: ${props => props.theme.header.heightMobile};
    }
    
    .logo {
        margin: .5rem 0; 
        display:flex;
    }
    
    .border-right {
        border-right: 1px solid rgba(255,255,255,0.1);
    }
    
    @media (max-width: ${props => props.theme.screen.sm}) {
        .border-right {
            border-right:0;
        }
    }
    
    .header-info {
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
    }
    
    &.hide {
        top: -${props => props.theme.header.height};
        
        @media (max-width: ${props => props.theme.screen.sm}) {
            top: -${props => props.theme.header.heightMobile};
        }
    
    }
    
    .nav-m {
       line-height: 1rem;
       display: flex;
       align-items: center;
       border-bottom: 2px solid ${props => props.theme.color.blue};
       border-top: 2px solid ${props => props.theme.color.blue};
       padding: 1rem 0;
       
      :focus {
        outline: none;
      }
      .menu_label {
        display: inline-block;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
      }
      &.open {
        button {
          div {
            background: ${props => props.theme.color.red};
            &.second {
              opacity: 0;
            }
            &.first {
              transform: rotate(45deg);
              top: .35rem;
            }
            &.third {
              transform: rotate(-45deg);
              bottom: .55rem;
            }
          }
        }
      }
      button {
        height: 1rem;
        width: 1.5rem;
        position: relative;
        background: transparent;
        padding: 0;
        border: none;
        div {
          transition: all 0.2s;
          height: 2px;
          width: 100%;
          background: ${props => props.theme.color.red};
          position: absolute;          
          &.first {
            top: 0;
            left: 0;
          }
          &.second {
            top: calc(50% - 1px);
            left: 0;
          }
          &.third {
            bottom: 0;
            left: 0;
          }
        }
      }
    }
    
    
    .nav-mm {
      list-style: none;
      display: block;
      height: 100%;
      font-size: 1rem;
      line-height: 2rem;
      overflow-y: auto;
      
      // text-transform: uppercase;
      transform: translateX(${props => (props.open ? '0%' : '100%')});
      transition: transform 0.3s ease;
      
      position: fixed;
      top: ${props => props.theme.header.heightMobile};
      left: 0;
      width: 100%;
      background: ${props => props.theme.color.blue};
      color: ${props => props.theme.color.white};
      // height: calc(100vh - ${props => props.theme.header.heightMobile});
      margin: 0;
      padding: 1.1rem 1.1rem 0;
      @media (min-width: ${props => props.theme.screen.sm}) {
        padding: 0 2.2rem;
      }
      
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        height: inherit;
      }
      
      ul.sub-menu {
        padding-left: 1rem;
      }
      
      li {      
        border-bottom: 1px solid rgba(255,255,255,0.1);
        padding: .2rem 0;
      }
      
      ul li:last-child {
        border-bottom: none;
      }
      
      a {
        font-family: ${props => props.theme.font.secondary};
        font-weight: ${props => props.theme.font.normal};
        color: ${props => props.theme.color.white};
        margin-bottom: 1.2rem 0.4rem;
        text-decoration: none;
        letter-spacing: .02rem;
        &.divider {
          margin-bottom: 2rem;
          position: relative;
          z-index: -99;
          &:after {
            content: '';
            position: absolute;
            bottom: -1rem;
            left: 0;
            width: 100%;
            height: 0.2rem;
            background: ${props => props.theme.color.darkgray};
          }
        }
      }
      
      a.active {        
        color: ${props => props.theme.color.white};
        border-left: 5px solid ${props => props.theme.color.red};
        padding-left: 5px;
        cursor: default;
      }
      p {
        font-family: ${props => props.theme.font.secondary};
        font-weight: ${props => props.theme.font.medium};
        // color: ${props => props.theme.color.lightgray};
        margin: 5px 0;
        letter-spacing: .02rem;
        cursor: default;
       }
      .accent-link-menu {
        background-color: #59a139;
        color: #fff!important;
        padding: 5px 10px;
        border-radius: 8px;
        text-transform: uppercase;
        font-family: ${props => props.theme.font.secondary};
        font-weight: ${props => props.theme.font.extrabold};
        color: ${props => props.theme.color.black};
        margin-bottom: 0.2rem;
      }
      .accent-link-menu-2 {
        background-color: #4d6a79;
        color: #fff!important;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 8px;
      }
    }
`;

export const TopBar = styled.div`
    background: ${props => props.theme.color.blue};
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: ${props => props.theme.color.white};
    width: 100%;
    font-size: .85rem;
    padding: 0;
`;

export const BottomBar = styled.div`
    background: ${props => props.theme.color.blue};
    color: ${props => props.theme.color.white};
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
`;

export const PrimaryMenu = styled.div`
    list-style: none;
    margin: 0px 0px;
    padding: 0px 0px;
    
    ul {
    padding: 0;
    margin: 0;
    }
    
    li {
    margin: 0px 0px;
    padding: 0px 0px;
    display: inline-block;
    position: relative;
    }
    
    li a {
    display: block;
    letter-spacing: .06rem;
    font-weight: 500;
    font-size: .8rem;
    padding: 8px 20px;
    line-height: 35px;
    text-decoration: none;
    text-align: center;
    outline: none;
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    cursor: pointer;
    }
    
    li a.active, li:hover a, p.is-active {
    // color: ${props => props.theme.color.red};
    }
    
    
    p {
    display: block;
    margin: 0;
    letter-spacing: .06rem;
    font-weight: 500;
    font-size: .8rem;
    padding: 8px 35px 8px 8px;
    line-height: 35px;
    text-decoration: none;
    text-align: center;
    outline: none;
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    cursor: pointer;
    }
    
    li > a {
    padding: 7px 20px 7px 20px;
    }
    
    li:first-child > a {
    padding-left: 0px;
    }
    
    li.has-children > a {
    padding-right: 32px;
    }
    
    li.has-children > a:after, li.has-children > p:after {
    position: absolute;
    width: 9px;
    height: 14px;
    right: 18px;
    top: 50%;
    margin-top: -8px;
    font-weight: 900;
    font-family: "FontAwesome";
    content: "";
    font-size: 14px;
    line-height: 14px;
    }
    
    ul.sub-menu {
    border-top: 3px solid ${props => props.theme.color.red};
    float: none;
    margin: 0px 0px;
    padding: 0px 0px;
    background-color: #fff;
    -webkit-box-shadow: 0 8px 15px rgb(0 0 0 / 10%);
    box-shadow: 0 8px 15px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    transition: all 300ms ease-out;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;    
    }
    
    ul.sub-menu.open {
     top: 100%;
     visibility: visible;
     opacity: 1;
    }
    
    li ul {
    background: ${props => props.theme.color.white};
    color: ${props => props.theme.color.black};
    width: auto;
    position: absolute;
    top: 140%;
    left: 0%;
    z-index: 10;
    // visibility: hidden;
    // opacity: 0;
    // transition: all 300ms ease-out;
    // -webkit-transition: all 300ms ease-out;
    // -moz-transition: all 300ms ease-out;
    // -o-transition: all 300ms ease-out;
    // -ms-transition: all 300ms ease-out;    
    }
    
    // li:hover > ul {
    // top: 100%;
    // visibility: visible;
    // opacity: 1;
    // }
    
    li li {
    display: block;
    position: relative;
    }
    
    li li a {
    font-weight: 600;
    text-transform: uoppercase;
    min-width: 10rem;
    font-size: .8rem;
    color: ${props => props.theme.color.darkgray}!important;
    text-align: left;
    display: block;
    white-space: nowrap;
    padding: 8px 18px 8px 18px!important;
    position: relative;
    text-decoration: none;
    outline: none;
    transition: all 100ms ease;
    -webkit-transition: all 100ms ease;
    -moz-transition: all 100ms ease;
    -o-transition: all 100ms ease;
    -ms-transition: all 100ms ease;
    border-bottom: 1px solid #ebebeb;
    }
    
    li li a:hover, li li a.active {
    background: ${props => props.theme.color.red};
    color: ${props => props.theme.color.white}!important;    
    }    
    
    li li a:last-child {
    border-bottom: 0;
    }
`;

export const LanguageSelector = styled.div`

    @media (max-width: ${props => props.theme.screen.sm}) {
        text-align: end;
    }
  
  .a-lang {
    background: inherit;
    text-decoration: none;
    display: inline-block;
    width: 4rem;
    text-align: center;
    font-weight: 600;
    
    @media (max-width: ${props => props.theme.screen.sm}) {
        border: 1px solid rgba(255,255,255,0.1);
    }
    
  }
  
  a.active-lang {
    background: ${props => props.theme.color.red};  
    border: none;  
    width: 4rem;
  }
  
  .a-lang:hover {
    &:hover {
        color: ${props => props.theme.color.red};
        background: initial;
    }
  }
  
  .a-lang.active-lang:hover {
    color:${props => props.theme.color.white};;
    background: ${props => props.theme.color.red};
    cursor: default;
  }
  
`;


