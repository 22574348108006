import React from "react"
import { ThemeProvider } from "styled-components"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
import { getCurrentLangKey } from 'ptz-i18n'

import theme from "../style/theme"
import GlobalStyles from "../style/GlobalStyles"
import { Wrapper } from "../style/layout.style"
import Header from "./header"
import Anchor from "./anchor"
import { Hero } from "./hero.js"
import { Fixed as ContainerFixed, Fluid as ContainerFluid, FullWidth as ContainerFullWidth } from "./container.js"
import { One as SectionOne, Two as SectionTwo, FullWidth as SectionFullWidth} from "./splitsections.js"
import { Button } from "./button.js"
import Sidebar from "./sidebar.js"
import { Footer, Copyright, PIva, Patamu } from "./footer"
import Seo from "./seo"
import Card from "./card"
import Team from "./team"
import ImageGallery from "./imageGallery"
import Progetti from "./progetti"
import Animation from "./animation.js"
import AnimationSmall from "./animationSmall.js"
import AnimationRubik from "./animationRubìk"


const siteLanguages = require('../utils/languages');
const { langs, defaultLangKey } = siteLanguages;

const components = {
    Link,
    Anchor,
    ContainerFixed, ContainerFluid, ContainerFullWidth,
    SectionOne, SectionTwo, SectionFullWidth,
    Button,
    Sidebar,
    Copyright, PIva, Patamu,
    Card,
    Team,
    ImageGallery,
    Progetti,
    Animation,
    AnimationSmall,
    AnimationRubik
};


const Layout = ({path, children, pageContext}) => {
    const { title, description } = pageContext.frontmatter || [];
    // list of pages with sidebar
    let pathsWithSidebar = [
        "/services/servizio1/",
        "/en/services/servizio1/",
        "/services/servizio2/",
        "/en/services/servizio2/",
        "/services/servizio3/",
        "/en/services/servizio3/",
    ];
    // check if current page should show sidebar
    const hasSidebar = pathsWithSidebar.indexOf(path) !== -1;

    // get current path langKey
    const langKey = getCurrentLangKey(langs, defaultLangKey, path);
    // console.log(langKey)

    return (
        <ThemeProvider theme={theme}>
            <MDXProvider components={components}>
                <Wrapper id={"top"}>
                    <GlobalStyles />
                    <Seo title={title} description={description} />
                    <Header currentPath={path} currentLang={langKey} />
                    <main className="site-content">
                        {
                            hasSidebar ?
                                <>
                                    <Hero animation backgroundImage backgroundColor="#184783" textColor="white" h1={pageContext.frontmatter.h1} h2={pageContext.frontmatter.h2} />
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-4 order-2 order-lg-1">
                                                <Sidebar path={path} pathsWithSidebar={pathsWithSidebar} />
                                            </div>
                                            <div className="col-lg-8 p-0 order-1 order-lg-2">
                                                {children}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <Hero gradient contentPadding="5" backgroundColor="#002c60" textColor="white" h1={pageContext.frontmatter.h1} h2={pageContext.frontmatter.h2} />
                                    {children}
                                </>
                        }
                    </main>
                    <Footer background={theme.color.blue} color={theme.color.white} />
                </Wrapper>
            </MDXProvider>
        </ThemeProvider>
    )
};

export default Layout;