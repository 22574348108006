import styled from 'styled-components'

export const Wrapper = styled.div`
    border-top: calc(${props => props.theme.header.height}) solid ${props => props.theme.color.blue};
    // padding-top: 1rem;
    display: flex;
    // min-height: calc(100vh - ${props => props.theme.header.height});
    min-height: 100vh;
    flex-direction: column;
;
    
    @media (max-width: ${props => props.theme.screen.sm}) {
        border-top: calc(${props => props.theme.header.heightMobile}) solid ${props => props.theme.color.darkblue};
        // padding-top: 1rem;
        // min-height: calc(100vh - ${props => props.theme.header.heightMobile});
        min-height: 100vh;
    }
    
    & .site-content {
        flex: 1 0 auto;
    }
`;