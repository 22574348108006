import React from "react"
import { Link } from "gatsby"

import { Button } from "./button"
// import { jsonConfigSito } from "../utils/jsonData"
// import { useFetch } from "../utils/useFetch"
import { Wrapper } from "../style/sidebar.style"


const SidebarIT = () => {
    // const { data } = useFetch(jsonConfigSito, {});
    // const { footer: { patamu } = { patamu: "" } } = data;
    // console.log(data);
    return (
      <Wrapper className="sidebar">
          <section className="widget">
              <h4>
                  Servizi
              </h4>
          {/*<ul>*/}
          {/*    <li><Link to="/" className="me-2" activeClassName="active">Home</Link></li>*/}
          {/*    <li className="has-children">*/}
          {/*        <p>Item 2 <span className="d-inline-block d-md-none">:</span></p>*/}
          {/*        <ul className="sub-menu">*/}
          {/*            <li><Link to="/services/servizio1/" activeClassName="active">Servizio 1</Link></li>*/}
          {/*            <li><Link to="/services/servizio2/" activeClassName="active">Servizio 2</Link></li>*/}
          {/*            <li><Link to="/services/servizio3/" activeClassName="active">Servizio 3</Link></li>*/}
          {/*        </ul>*/}
          {/*    </li>*/}
          {/*    <li><Link to="/test/" className="me-2" activeClassName="active">Test</Link></li>*/}
          {/*    <li><Link to="/test2/" className="me-2" activeClassName="active">Test2</Link></li>*/}
          {/*    <li><Link to="/abc/" className="me-2" activeClassName="active">ABC</Link></li>*/}
          {/*</ul>*/}
              <ul>
                  <li><Link to="/services/servizio1/" activeClassName="active">Servizio 1</Link></li>
                  <li><Link to="/services/servizio2/" activeClassName="active">Servizio 2</Link></li>
                  <li><Link to="/services/servizio3/" activeClassName="active">Servizio 3</Link></li>
              </ul>
          </section>
          <section className="widget">
              <h5>Lorem ipsum dolor sit amet</h5>
              <p>Consectetur adipiscing elit. Morbi a sem eget urna laoreet placerat nec a orci.</p>
              {/*<Button targetBlank fullwidth large solid color="#cc3837" pulse inline hrefJSON={patamu}>Click here</Button>*/}
              <Button fullwidth large solid color="#cc3837" pulse inline>Contattaci</Button>
          </section>
      </Wrapper>
    );
};

const SidebarEN = () => {
    // const { data } = useFetch(jsonConfigSito, {});
    // const { footer: { patamu } = { patamu: "" } } = data;
    // console.log(data);
    return (
        <Wrapper className="sidebar">
            <section className="widget">
                <h4>
                    Services
                </h4>
                {/*<ul>*/}
                {/*    <li><Link to="/en/" className="me-2" activeClassName="active">Home</Link></li>*/}
                {/*    <li className="has-children">*/}
                {/*        <p>Item 2 <span className="d-inline-block d-md-none">:</span></p>*/}
                {/*        <ul className="sub-menu">*/}
                {/*            <li><Link to="en/services/servizio1/" activeClassName="active">Subitem 1 with long title</Link></li>*/}
                {/*            <li><Link to="/en/services/servizio2/" activeClassName="active">Subitem 2</Link></li>*/}
                {/*            <li><Link to="/en/services/servizio3/" activeClassName="active">Subitem 3</Link></li>*/}
                {/*        </ul>*/}
                {/*    </li>*/}
                {/*    <li><Link to="/en/test/" className="me-2" activeClassName="active">Test</Link></li>*/}
                {/*    <li><Link to="/en/test2/" className="me-2" activeClassName="active">Test2</Link></li>*/}
                {/*    <li><Link to="/en/abc/" className="me-2" activeClassName="active">ABC</Link></li>*/}
                {/*</ul>*/}
                <ul>
                    <li><Link to="en/services/servizio1/" activeClassName="active">Service 1</Link></li>
                    <li><Link to="/en/services/servizio2/" activeClassName="active">Service 2</Link></li>
                    <li><Link to="/en/services/servizio3/" activeClassName="active">Service 3</Link></li>
                </ul>
            </section>
            <section className="widget">
                <h5>Lorem ipsum dolor sit amet</h5>
                <p>Consectetur adipiscing elit. Morbi a sem eget urna laoreet placerat nec a orci.</p>
                {/*<Button targetBlank fullwidth large solid color="#cc3837" pulse inline hrefJSON={patamu}>Click here</Button>*/}
                <Button fullwidth large solid color="#cc3837" pulse inline>Contact us</Button>
            </section>
        </Wrapper>
    );
};

const Sidebar = ({ path, pathsWithSidebar}) => {

    // check if current lang is IT
    const isDefaultLang = !path.includes("/en/");

    // check if current path is one of the listed pages
    const hasSidebar = pathsWithSidebar.indexOf(path) !== -1;

    // show the correct sidebar where needed
    let ShowSidebar =  (
        (hasSidebar && isDefaultLang) ? <SidebarIT /> : // if
        (hasSidebar && !isDefaultLang) ? <SidebarEN /> : // else if
        // foo === 'c' ? 3 : // else if
        null // else
    );

    // console.log(hasSidebar, isDefaultLang);

    return (
        <>{ShowSidebar}</>
    );
};

export default Sidebar;