import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useScrollYPosition } from "react-use-scroll-position"
import { getLangs, getUrlForLang } from "ptz-i18n"

import {useScrollDirection} from "../utils/useScrollDirection"
import {useWindowWidth} from "../utils/useWindowWidth"
import {Wrapper, TopBar, BottomBar, PrimaryMenu, LanguageSelector} from "../style/header.style"
import {StaticImage} from "gatsby-plugin-image";
const siteLanguages = require('../utils/languages');
const { langs, defaultLangKey } = siteLanguages;


const Header = ({gradient, currentLang, currentPath}) => {
    const scrollDirection = useScrollDirection();
    const [open, setOpen] = useState(false);
    const scrollY = useScrollYPosition();
    const windoWidth = useWindowWidth();

    const homeLink = `/${currentLang}/`.replace(`/${defaultLangKey}/`, '/');
    const langsMenu = getLangs(langs, currentLang, getUrlForLang(homeLink, currentPath)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
    const activeCurrentLink = langsMenu.filter(lang => lang.selected === true)[0].link;
    const translateCurrentLink = langsMenu.filter(lang => lang.selected === false)[0].link;

    useEffect(() => {
        setOpen(false);
    }, [currentPath, windoWidth, scrollY]);



    return (
        <>
            <Wrapper className={`header ${ scrollDirection === "down" ? "hide" : "show"}`} open={open} gradient={gradient}>
                <TopBar className="d-none d-md-flex">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                Topbar
                            </div>
                            <div className="col-6 text-end">
                                {/*Opening Hours : Monday to Friday - 9am to 5pm*/}
                                <LauguageSelect langKey={currentLang} active={activeCurrentLink} translate={translateCurrentLink} />
                            </div>
                        </div>
                    </div>
                </TopBar>
                <div className="container py-2">
                    <div className="row justify-content-center align-items-center">
                        <div className="col border-right">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-auto me-auto">
                                    <Link to={homeLink} title={"Zenit Security"}>
                                        <StaticImage src="../assets/images/ZenitSecurity_logo_bn.svg" className="logo" alt="Zenit Security" width={207} height={63} placeholder="blurred" />
                                    </Link>
                                </div>
                                <div className="col-auto d-md-none ps-0">
                                    <div className={`nav-m${open ? ' open' : ''}`}
                                         role="button"
                                         tabIndex={0}
                                         onClick={() => setOpen(!open)}
                                         onKeyDown={() => setOpen(!open)}
                                    >
                                        <span className="menu_label me-2">MENU</span>
                                        <button
                                            aria-label="Toggle Menu"
                                            className="icon"
                                        >
                                            <div className="first" />
                                            <div className="second" />
                                            <div className="third" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={`nav-mm${open ? ' open' : ''}`}>
                                <LauguageSelect langKey={currentLang} active={activeCurrentLink} translate={translateCurrentLink} />
                                <hr />
                                <MenuLinks langKey={currentLang} activeCurrentLink={activeCurrentLink} />
                            </div>
                        </div>
                        <div className="d-none d-md-block col-md-8 col-lg-9 text-end text-end">
                            <span className="header-info ms-1">{currentLang === 'it' ? 'IT: Lorem ipsum dolor sit amet, consectetur adipiscing' : 'EN: Lorem ipsum dolor sit amet, consectetur adipiscing'}</span>
                        </div>
                    </div>
                </div>
                <BottomBar className="d-none d-md-flex">
                    <nav className="container">
                        <PrimaryMenu>
                            <MenuLinks langKey={currentLang} activeCurrentLink={activeCurrentLink} />
                        </PrimaryMenu>
                    </nav>
                </BottomBar>
            </Wrapper>
        </>
    )
};

const MenuLinks = ({langKey, activeCurrentLink}) => {

    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);

    const isActive = () => {
        setOpen(!open);
        setActive(true);
    };

    return (
        <>
        {
            langKey === "it" ?
                <ul>
                    <li>
                        <Link to="/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>
                            <span data-hover="Home">
                            Home
                            </span>
                        </Link>
                    </li>
                    <li className="has-children"
                        onClick={() => setOpen(true)}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                    >
                        <p className={`${(active || activeCurrentLink.includes("/services/")) ? 'is-active' : ''}`}>Servizi <span className="d-inline-block d-md-none">:</span></p>
                        <ul className={`sub-menu${open ? ' open' : ''}`}>
                            <li>
                                <Link to="/services/servizio1/"
                                      activeClassName="active"
                                      onClick={() => isActive()}
                                >
                                    Servizio 1
                                </Link>
                            </li>
                            <li>
                                <Link to="/services/servizio2/"
                                      activeClassName="active"
                                      onClick={() => isActive()}
                                >
                                    Servizio 2
                                </Link>
                            </li>
                            <li>
                                <Link to="/services/servizio3/"
                                      activeClassName="active"
                                      onClick={() => isActive()}
                                >
                                    Servizio 3
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/projects/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>Progetti</Link>
                    </li>
                    <li>
                        <Link to="/team/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>Team</Link>
                    </li>
                    <li>
                        <Link to="/test/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>Test</Link>
                    </li>
                    <li>
                        <Link to="/abc/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>ABC</Link>
                    </li>
                </ul>
                :
                <ul>
                    <li>
                        <Link to="/en/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>Home</Link>
                    </li>
                    <li className="has-children"
                        onClick={() => setOpen(true)}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                    >
                        <p className={`${(active || activeCurrentLink.includes("/services/")) ? 'is-active' : ''}`}>Services <span className="d-inline-block d-md-none">:</span></p>
                        <ul className={`sub-menu${open ? ' open' : ''}`}>
                            <li>
                                <Link to="/en/services/servizio1/"
                                      activeClassName="active"
                                      onClick={() => isActive()}
                                >
                                    Service 1
                                </Link>
                            </li>
                            <li>
                                <Link to="/en/services/servizio2/"
                                      activeClassName="active"
                                      onClick={() => isActive()}
                                >
                                    Service 2
                                </Link>
                            </li>
                            <li>
                                <Link to="/en/services/servizio3/"
                                      activeClassName="active"
                                      onClick={() => isActive()}
                                >
                                    Service 3
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li><Link to="/en/projects/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>Projects</Link></li>
                    <li><Link to="/en/team/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>Team</Link></li>
                    <li><Link to="/en/test/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>Test</Link></li>
                    <li><Link to="/en/abc/" className="me-2" activeClassName="active" onClick={() => setActive(false)}>ABC</Link></li>
                </ul>
        }
        </>
    )
};

const LauguageSelect = ({langKey, active, translate}) => {

    return (
        <>
        {
            langKey === "it" ?
                <>
                    <LanguageSelector>
                        <Link to={active} className="a-lang" activeClassName="active-lang" title="Italiano">IT</Link>
                        <Link to={translate} className="a-lang" activeClassName="active-lang" title="English">EN</Link>
                    </LanguageSelector>
                </>
                :
                <>
                    <LanguageSelector>
                        <Link to={translate} className="a-lang" activeClassName="active-lang" title="Italiano">IT</Link>
                        <Link to={active} className="a-lang" activeClassName="active-lang" title="English">EN</Link>
                    </LanguageSelector>
                </>
        }
        </>
    )
};

export default Header