import styled, { css, keyframes } from "styled-components"
import {Link} from "gatsby";

const pulseAnimation = keyframes`
 0% {
      -webkit-box-shadow: 0 0 0 0 rgb(255 0 0 / 60%);
    }
  
    50% {
      -webkit-box-shadow: 0 0 0 5px rgb(255 0 0 / 40%);
    }
  
    100% {
      -webkit-box-shadow: 0 0 0 0 rgb(255 0 0 / 30%);
    }
`;

export const StyledAnchor = styled.a`
    text-decoration: none;
`;

// const StyledAnchorLink = styled(AnchorLink)`
//     text-decoration: none;
// `;
export const StyledAnchorLink = styled(Link)`
    text-decoration: none;
`;

export const StyledButton = styled.button`
    letter-spacing: .03rem;
    display: ${props => props.inline === true ? `inline-block` : `block`};
    padding: ${props => props.small === true ? `6px 10px` : `10px 15px`};
    margin: ${props => props.inline === true ? `0 15px 10px 0` : `0 0 10px 0`};
    width: ${props => props.fullwidth === true && `100%`};
    font-weight:  ${props => props.theme.font.semibold};
    
    ${props => props.small === true && props.theme.font_size.xsmall};
    ${props => props.medium === true && `font-size: 1.2rem; line-height: 1.8rem; svg {width: 0.75em;}`};
    ${props => props.medium === true && `font-family: ${props.theme.font.secondary}; font-weight: ${props.theme.font.medium}`};
    ${props => props.large === true && /*props.theme.font_size.larger*/ `font-size: 1.8rem; line-height: 2.4rem; ; svg {width: 1em;}`};
    ${props => props.large === true && `font-family: ${props.theme.font.secondary}; font-weight: ${props.theme.font.medium}`};
    
    background: ${props => props.solid !== true ? `transparent` :  (props.color || props.theme.color.black)};
    border-radius: 8px;
    border: 2px solid ${props => props.solid !== true ? (props.color || props.theme.color.black) : `transparent`};
    box-shadow: 4px 4px 0 rgba(0,0,0,.4);
    color: ${props => props.solid !== true ? (props.color || props.theme.color.black) : props.theme.color.white};
    cursor: pointer;
    outline: transparent;
    position: relative;
    -webkit-transition: box-shadow 0.15s ease;
    transition: box-shadow 0.15s ease;
    
    ${props => props.pulse === true ? css`
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: ${pulseAnimation};
    `
    :
    ``};
    
    @media (max-width: ${props => props.theme.screen.sm}) {
        ${props => props.large === true && `font-size: 24px; line-height: 28px;`};         
    }
    
    & svg {
        ${props => props.large === true ? `vertical-align: sub;` : `vertical-align: text-top;`};        
        margin-right: 6px;
    }
    
    & .buttonspan {
        bottom: -3px;
        left: -3px;
        position: absolute;
        right: -3px;
        top: -3px;
        z-index: 1;
    }
    & .buttonspan:nth-of-type(1):hover,
    & .buttonspan:nth-of-type(2):hover,
    & .buttonspan:nth-of-type(3):hover,
    & .buttonspan:nth-of-type(4):hover {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        z-index: 2;
    }
    & .buttonspan:nth-of-type(1):hover ~ b:nth-of-type(1),
    & .buttonspan:nth-of-type(2):hover ~ b:nth-of-type(2),
    & .buttonspan:nth-of-type(3):hover ~ b:nth-of-type(3),
    & .buttonspan:nth-of-type(4):hover ~ b:nth-of-type(4) {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
    }
    & .buttonspan:nth-of-type(1) {
        -webkit-clip-path: polygon(0 0, 100% 0, 50% 50%, 50% 50%);
        clip-path: polygon(0 0, 100% 0, 50% 50%, 50% 50%);
    }
    & .buttonspan:nth-of-type(2) {
        -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 50%);
        clip-path: polygon(100% 0, 100% 100%, 50% 50%);
    }
    & .buttonspan:nth-of-type(3) {
        -webkit-clip-path: polygon(0 100%, 100% 100%, 50% 50%);
        clip-path: polygon(0 100%, 100% 100%, 50% 50%);
    }
    & .buttonspan:nth-of-type(4) {
        -webkit-clip-path: polygon(0 0, 0 100%, 50% 50%);
        clip-path: polygon(0 0, 0 100%, 50% 50%);
    }
    & b {
        background: ${props => props.solid !== true ? (props.color || props.theme.color.black) : props.theme.color.white};
        border: 2px solid ${props => props.color || props.theme.color.black};
        border-radius: 8px;
        bottom: -3px;
        font-weight: ${props => props.large === true ? props.theme.font.medium : props.theme.font.semibold};
        color: ${props => props.solid !== true ? props.theme.color.white : (props.color || props.theme.color.black)};
        left: -3px;
        padding: ${props => props.small === true ? `6px 11px` : `10px 15px`};
        position: absolute;
        right: -3px;
        top: -3px;
        -webkit-transition: -webkit-clip-path 0.25s ease;
        transition: -webkit-clip-path 0.25s ease;
        transition: clip-path 0.25s ease;
        transition: clip-path 0.25s ease, -webkit-clip-path 0.25s ease;
         
    }
    & b:nth-of-type(1) {
        -webkit-clip-path: inset(0 0 100% 0);
        clip-path: inset(0 0 100% 0);
    }
    & b:nth-of-type(2) {
        -webkit-clip-path: inset(0 0 0 100%);
        clip-path: inset(0 0 0 100%);
    }
    & b:nth-of-type(3) {
        -webkit-clip-path: inset(100% 0 0 0);
        clip-path: inset(100% 0 0 0);
    }
    & b:nth-of-type(4) {
        -webkit-clip-path: inset(0 100% 0 0);
        clip-path: inset(0 100% 0 0);
    }
    
  }

`;