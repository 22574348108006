import React, {useEffect, useState} from "react"
import { navigate } from "gatsby"

import ImageGallery from "./imageGallery"
import { Button } from "./button";
import { useFetch } from "../utils/useFetch"
import { jsonConfigProgetti } from "../utils/jsonData"
import { MyPaginate, StyledCard, StyledButton }  from "../style/progetti.style"


const Progetti = ({ itemsPerPage }) => {
    const { data } = useFetch(jsonConfigProgetti, {});
    const { progetti: { lista } = { lista: [] } } = data;
    const setItemsPerPage = !itemsPerPage ? 1 : itemsPerPage;
    // console.log(itemsPerPage, setItemsPerPage);

    // We start with an empty list of items.

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState("");
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // crea array di tutti i filtri esistenti nel json
    let filtersArray = [];
    lista.map((e) => !filtersArray.includes(e.filtro) && e.filtro !== undefined && filtersArray.push(e.filtro));


    let results = [];
    // useEffect(() => {
    //     console.log("filtro selezionato: ",selectedFilter);
    //     lista.map(() => (selectedFilter === "") && results.push(lista) && console.log(results));
    // }, []);
    //
    useEffect(() => {
            console.log("filtro selezionato: ",selectedFilter);
            results=lista;
            lista.map((e) => selectedFilter !== "" && selectedFilter === e.filtro && !results.includes(e) && results.push(e));
    }, [selectedFilter, setSelectedFilter]);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + setItemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(lista.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(lista.length / setItemsPerPage));
        // setCurrentItems(results.slice(itemOffset, endOffset)) && console.log(currentItems);
        // setPageCount(Math.ceil(results.length / setItemsPerPage));
    }, [itemOffset, setItemsPerPage, data, selectedFilter, setSelectedFilter]);




    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * setItemsPerPage % lista.length;
        // const newOffset = event.selected * setItemsPerPage % results.length;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
        // scroll to top of page
        navigate('#top')
        // console.log(window.location.hash)
    };

    // Remove # from url
    useEffect(() => {
        const hashtag = window.location.hash;
        if (hashtag) {
            const currentURL = window.location.href;
            const newURL = currentURL.split("#")[0];
            window.history.replaceState("", "", newURL)
        }
    });



    return (
        <>
            {/*<h3>Progetti</h3>*/}

            <div id="lista_progetti" className="items px-0">

                {/*<div>*/}
                {/*    <StyledButton inline solid small targetBlank color={"#333a45"} className="mt-1 mt-sm-3 mb-sm-2 filter-button" onClick={() => setSelectedFilter("")}>*/}
                {/*        Tutti*/}
                {/*    </StyledButton>*/}
                {/*    {filtersArray.map( (e, i) => {*/}
                {/*    return(*/}
                {/*        <StyledButton inline small targetBlank color={"#333a45"} className="mt-1 mt-sm-3 mb-sm-2 filter-button" key={i+"filtro"} onClick={() => setSelectedFilter(e)}>*/}
                {/*            {e}*/}
                {/*        </StyledButton>*/}
                {/*    )}*/}
                {/*)}*/}
                {/*</div>*/}
                {currentItems && currentItems.map((e, i) => {
                    let imagesArray = [];
                    e.info.map( (e) => e.key === "image" && imagesArray.push(e.val) );
                    return (

                    <StyledCard className="my-4" key={i+"div"+e}>
                        <div className="row">
                            <div className={
                                `col-12 justify-content-center align-self-center 
                                 ${ e.orientamento === "verticale" ? "col-md-5 col-lg-3 col-xl-2" :
                                   e.orientamento === "orizzontale" ? "col-lg-4" :
                                   "d-none"}
                                 `}
                            >
                                <ImageGallery images={imagesArray} key={i+"gallery"} />
                            </div>
                            <div className={
                                `col-12 justify-content-center align-self-center 
                                 ${ e.orientamento === "verticale" ? "col-md-7 col-lg-9 col-xl-10" : 
                                   e.orientamento === "orizzontale" ? "col-lg-8" : 
                                   ""}
                                 `}>
                                <h4 className="d-inline-block">{e.titolo}</h4>
                                <hr />
                                {e.info &&
                                <div className="row">
                                    <div className="col-12 py-2">
                                        <div className="row">
                                        { e.info.map((e, index) => (
                                            e.key !== "descrizione" && !e.val.includes("http") && !e.val.includes("https") &&
                                            <div className="col-lg-6" key={index+"_info"+e.val}>
                                                <p className="m-0"><span className="key">{e.key}:</span> {e.val}</p>
                                            </div>
                                        ))
                                        }
                                        </div>
                                        { e.info.map((e, index) => (
                                            e.key === "descrizione" &&
                                                <p className="m-0 descrizione" key={index+"_descr"+e.val}><span className="key">{e.key}:</span> {e.val}</p>
                                        ))}
                                        { e.info.map((e, index) => (
                                            ( (e.val.includes("http") || e.val.includes("https")) && e.key !== "image") &&
                                            <Button inline small targetBlank href={e.val} color={"#184783"} className="mt-1 mt-sm-3 mb-sm-2" key={index+"_link"+e.val}>
                                                {e.key}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </StyledCard>

                )
                })}
            </div>
            <MyPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextLabel=">"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    )
};

export default Progetti