import styled, {keyframes, css} from 'styled-components'

const gradient = keyframes`
    0% {background-position:0% 50%}
    50% {background-position:100% 50%}
    100% {background-position:0% 50%}
`;

const square = keyframes`
    0% {
        transform: translateY(0);
}
    100% {
        transform: translateY(-700px) rotate(600deg);
`;

export const StyledContainerFluid = styled.div`    

    overflow: hidden;   
    
    ${props => props.gradient === true && css`
    background: linear-gradient(-45deg, #184783, #002c60);
    background-size: 600% 600%;
    -webkit-animation: ${gradient} 30s ease infinite;
    -moz-animation: ${gradient} 30s ease infinite;
    -o-animation: ${gradient} 30s ease infinite;
    animation: ${gradient} 30s ease infinite;
    `};
    
    background-color: ${props => props.background ? props.background : ``};
     
    position: relative;
    
    h1, h2 {
        color: ${props => props.textColor ? props.textColor : `inherit`}; 
    }
    
    h2 {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-family: 'Inter',sans-serif;
        font-weight: 400;
        margin: 0 0 1.3rem 0;
    }
    
    & .background-image {
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    
    & .content {
        z-index: 10;
    }
    
    .bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: ${square} 25s infinite;
  animation: ${square} 25s infinite;
  transition-timing-function: linear;
}
.bg-bubbles li:nth-child(1) {
  left: 10%;
}
.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
          animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
  left: 70%;
}
.bg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
          animation-delay: 11s;
}
    
`;