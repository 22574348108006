import React from "react"
import PropTypes from "prop-types"
import { useLocation } from '@reach/router'
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo =({ meta, title, description }) => {
    const { site } = useStaticQuery(
        graphql`
          {
          mdx {
            frontmatter {
              description
              title
            }
          }
          site {
            siteMetadata {
              title
              description
              siteUrl
              author
              locale
              og_type
              og_url
              og_title
              og_image
              og_description
              og_locale
              languages {
                defaultLangKey
                langs
              }
            }
          }
    }
    `
    );

    const metaTitle = title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title;
    const metaDescription = description ? description : site.siteMetadata.description;
    const location = useLocation();

    return (
        <Helmet
            htmlAttributes={{
                lang: `${location.pathname.includes("/en/") ? "en" : "it"}`,
            }}
            title={metaTitle}
            meta={[
                {
                    httpEquiv: `Cache-Control`,
                    content: `no-cache, no-store, must-revalidate, max-age=0`,
                },
                {
                    httpEquiv: `Pragma`,
                    content: `no-cache`,
                },
                {
                    httpEquiv: `Expires`,
                    content: `0`,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                // Facebook OpenGraph
                {
                    property: `og:type`,
                    content: site.siteMetadata.og_type,
                },
                {
                    property: `og:url`,
                    content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:image`,
                    content: site.siteMetadata.og_image,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },

                {
                    property: `og:locale`,
                    content: `${location.pathname.includes("/en/") ? "en_EN" : "it_IT"}`,
                },
            ]
            .concat(meta)}
            link={[
                {
                    rel: 'canonical',
                    href: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
                },
                {
                    rel: 'alternate',
                    hrefLang: 'x-default',
                    href: `${!location.pathname.includes("/it/") && site?.siteMetadata?.siteUrl+location.pathname.replace('/en/', '/')}`,
                },
                {
                    rel: 'alternate',
                    hrefLang: 'it',
                    href: `${!location.pathname.includes("/it/") ?
                        site?.siteMetadata?.siteUrl+location.pathname.replace('/en/', '/')
                        :
                        site?.siteMetadata?.siteUrl+location.pathname}`,
                },
                {
                    rel: 'alternate',
                    hrefLang: 'en',
                    href: `${!location.pathname.includes("/en/") ?
                        site?.siteMetadata?.siteUrl+location.pathname.replace('/', '/en/')
                        :
                        site?.siteMetadata?.siteUrl+location.pathname}`,
                },
            ]}
        />

    )
};

Seo.defaultProps = {
    lang: `it`,
    meta: [],
    description: ``,
};

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
};

Seo.defaultProps = {
    lang: `it`,
    meta: [],
    description: ``,
};


export default Seo;