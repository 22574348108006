import styled from 'styled-components'

export const StyledCard = styled.div` 

  a.inner {
    display: block;
    text-decoration: none;
    font-weight: initial;
  }  
  
  .inner {
    cursor: ${props => props.isClickable ? `pointer` : `default`};
    background-color: ${props => props.background ? props.background : `white`};
    
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.5)!important;
  }
  
  .card_image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      filter: contrast(70%);
      overflow: hidden;
      position: relative;
      transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
      background-image: url(${props => props.image && props.image});
      max-height: 12rem;
  }
  .card_image::before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
  @media (min-width: 40rem) {
      .card_image::before {
        padding-top: 66.6%;
      }
  }
  
  .inner-content {
    padding: 1.5rem;
  }
  
  .inner:hover {
    background-color: ${props => props.isClickable ? props.theme.color.red : "white"};
    color: ${props => props.isClickable ? props.theme.color.white: "inherit"};
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.5)!important;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    
    .readmore {
      color:  ${props => props.theme.color.white};    
      -webkit-transform: translateX(10px);
            -moz-transform: translateX(10px);
            -ms-transform: translateX(10px);
            -o-transform: translateX(10px);
            transform: translateX(10px); 
    }
    
    .icon svg {
      font-size: 3rem;
      color:  ${props => props.theme.color.red};
      background: ${props => props.theme.color.white};
      padding: .5rem;
      border-radius: 50%;
      transform: rotate(360deg); 
    }
    
  }
  
  .icon svg {
      font-size: 3rem;
      color:  ${props => props.theme.color.white};
      background: ${props => props.theme.color.altlightblue};
      padding: .5rem;
      border-radius: 50%;
      margin-bottom: .5rem;
      transition: transform .7s ease-in-out;
  }
  
  .readmore {
    color: ${props => props.theme.color.altlightblue};
    font-size: 1.5rem;
    font-weight: 600;
    -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
  }
  
  
  
`;