import React from "react"
import styled from 'styled-components'

export const Wrapper = styled.div`

@media screen and (min-width: calc(${props => props.theme.screen.xs} + 1px)) {
   * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightgray;
}

.container {
  height: 100%;
  width: 100%;
  transform: translate(50%, 10%);
}
.container .cube {
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.container .cube > div {
  width: 25px;
  height: 25px;
  position: absolute;
}
.container .cube .top {
  background: #184783;
  transform: rotate(-45deg) skew(15deg, 15deg);
  border: 1px solid #184783;
}
.container .cube .left {
  background: #cc3837;
  border: 1px solid #cc3837;
  transform: rotate(15deg) skew(15deg, 15deg) translate(-50%, 100%);
}
.container .cube .right {
  background: white;
  border: 1px solid white;
  transform: rotate(-15deg) skew(-15deg, -15deg) translate(50%, 100%);
}
.container .cube:nth-child(1) {
  animation-name: anim-one;
}
.container .cube:nth-child(2) {
  animation-name: anim-two;
}
.container .cube:nth-child(3) {
  animation-name: anim-three;
}
.container .cube:nth-child(4) {
  animation-name: anim-four;
}
.container .one {
  animation-name: shadow-one;
}
.container .two {
  animation-name: shadow-two;
}
.container .three {
  animation-name: shadow-three;
}
.container .four {
  animation-name: shadow-four;
}

@keyframes anim-one {
  from {
    transform: translate(-42px, 0);
  }
  50% {
    transform: translate(-21px, -12px);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes anim-two {
  from {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  to {
    transform: translate(21px, 12px);
  }
}
@keyframes anim-three {
  from {
    transform: translate(-21px, 12px);
  }
  50% {
    transform: translate(-21px, 12px);
  }
  to {
    transform: translate(-42px, 0px);
  }
}
@keyframes anim-four {
  from {
    transform: translate(21px, 12px);
  }
  50% {
    transform: translate(0, 24px);
  }
  to {
    transform: translate(-21px, 12px);
  }
}
@keyframes shadow-one {
  from {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(-50%, 0);
  }
  50% {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(0, 0);
  }
  to {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(0, 50%);
  }
}
@keyframes shadow-two {
  from {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(-50%, 50%);
  }
  50% {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(-50%, 50%);
  }
  to {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(-50%, 0);
  }
}
@keyframes shadow-three {
  from {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(0, 50%);
  }
  50% {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(0, 50%);
  }
  to {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(0, 100%);
  }
}
@keyframes shadow-four {
  from {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(0, 100%);
  }
  50% {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(-50%, 100%);
  }
  to {
    transform: rotate(-45deg) skew(15deg, 15deg) translate(-50%, 50%);
  }
}
}

`;

const AnimationSmall = ({className}) => {
        return (
            <Wrapper>
                <div className={`container ${className ? className : ''}`}>
                    <div className="cube">
                        <div className="top"></div>
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                    <div className="cube">
                        <div className="top"></div>
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                    <div className="cube">
                        <div className="top"></div>
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                    <div className="cube">
                        <div className="top"></div>
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                </div>
            </Wrapper>
        )
};

export default AnimationSmall