import React from 'react';

const One = ({children, order, col, className, style}) => (
    <div col={col} className={`${order === "1" ? "order-lg-1" : ''} ${order === "2" ? "order-lg-2" : ''} col-lg-${col ? col : "6"} ${className ? className : ''} justify-content-center align-self-center ${order === "last" ? `order-lg-last` : ''}`} style={style}>
        {children}
    </div>
);

const Two = ({children, order, col, className, style}) => (
    <div col={col} className={`${order === "1" ? "order-lg-1" : ''} ${order === "2" ? "order-lg-2" : ''} col-lg-${col ? col : "6"} ${className ? className : ''} justify-content-center align-self-center ${order === "first" ? `order-lg-first` : ''}`} style={style}>
        {children}
    </div>
);

const FullWidth = ({children, order, col, className, style}) => (
    <div order={order} col={col} className={`col-lg-${col ? col : "12"} ${className ? className : ''} justify-content-center align-self-center`} style={style}>
        {children}
    </div>
);

export {One, Two, FullWidth};