exports.components = {
  "component---src-pages-abc-index-en-mdx": () => import("./../../../src/pages/abc/index.en.mdx" /* webpackChunkName: "component---src-pages-abc-index-en-mdx" */),
  "component---src-pages-abc-index-mdx": () => import("./../../../src/pages/abc/index.mdx" /* webpackChunkName: "component---src-pages-abc-index-mdx" */),
  "component---src-pages-index-en-mdx": () => import("./../../../src/pages/index.en.mdx" /* webpackChunkName: "component---src-pages-index-en-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-projects-index-en-mdx": () => import("./../../../src/pages/projects/index.en.mdx" /* webpackChunkName: "component---src-pages-projects-index-en-mdx" */),
  "component---src-pages-projects-index-mdx": () => import("./../../../src/pages/projects/index.mdx" /* webpackChunkName: "component---src-pages-projects-index-mdx" */),
  "component---src-pages-services-servizio-1-index-en-mdx": () => import("./../../../src/pages/services/servizio1/index.en.mdx" /* webpackChunkName: "component---src-pages-services-servizio-1-index-en-mdx" */),
  "component---src-pages-services-servizio-1-index-mdx": () => import("./../../../src/pages/services/servizio1/index.mdx" /* webpackChunkName: "component---src-pages-services-servizio-1-index-mdx" */),
  "component---src-pages-services-servizio-2-index-en-mdx": () => import("./../../../src/pages/services/servizio2/index.en.mdx" /* webpackChunkName: "component---src-pages-services-servizio-2-index-en-mdx" */),
  "component---src-pages-services-servizio-2-index-mdx": () => import("./../../../src/pages/services/servizio2/index.mdx" /* webpackChunkName: "component---src-pages-services-servizio-2-index-mdx" */),
  "component---src-pages-services-servizio-3-index-en-mdx": () => import("./../../../src/pages/services/servizio3/index.en.mdx" /* webpackChunkName: "component---src-pages-services-servizio-3-index-en-mdx" */),
  "component---src-pages-services-servizio-3-index-mdx": () => import("./../../../src/pages/services/servizio3/index.mdx" /* webpackChunkName: "component---src-pages-services-servizio-3-index-mdx" */),
  "component---src-pages-team-index-en-mdx": () => import("./../../../src/pages/team/index.en.mdx" /* webpackChunkName: "component---src-pages-team-index-en-mdx" */),
  "component---src-pages-team-index-mdx": () => import("./../../../src/pages/team/index.mdx" /* webpackChunkName: "component---src-pages-team-index-mdx" */),
  "component---src-pages-test-index-en-mdx": () => import("./../../../src/pages/test/index.en.mdx" /* webpackChunkName: "component---src-pages-test-index-en-mdx" */),
  "component---src-pages-test-index-mdx": () => import("./../../../src/pages/test/index.mdx" /* webpackChunkName: "component---src-pages-test-index-mdx" */)
}

