import styled from 'styled-components'

export const Wrapper = styled.div`
    
    img {
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.5)!important;
    }
    
    img:hover {
    // display: block;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.5)!important;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
    }
    
`;