import React, {useState} from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import { Wrapper } from "../style/imageGallery.style"

const ImageGallery = ({images}) => {

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    return(
        <Wrapper>
            <img className={`img-responsive ${ images.length === 1 ? "single-image" : ""}`} src={images[0]} alt={"Gallery"} title={"Gallery"} onClick={() => setIsOpen(true)} />
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex( (photoIndex + 1) % images.length )}
                    wrapperClassName={`image-gallery ${ images.length === 1 ? "single-image" : ""}`}
                />
            )}
        </Wrapper>
    )

};

export default ImageGallery;