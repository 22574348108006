const React = require("react");
const reactDomClient = require(`react-dom/client`);
const Layout = require("./src/components/layout").default;

require("./src/style/bootstrap-grid.min.css");

// fix: https://github.com/gatsbyjs/gatsby/discussions/31943#discussioncomment-2880513
exports.replaceHydrateFunction = () => {
    return (element, container) => {
        reactDomClient.createRoot(container).render(element)
    };
};

exports.wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
};