const theme = {
    font: {
        primary: `'Inter', sans-serif;`,
        secondary: `'Inter', sans-serif;`,
        thin: `100`,
        extralight: `200`,
        light: `300`,
        normal: `400`,
        medium: `500`,
        semibold: `600`,
        bold: `700`,
        extrabold: `900`,
    },
    font_size: {
        xxxsmall: "font-size: 0.7rem; line-height: 1.1rem;",
        xxsmall: "font-size: 0.8rem; line-height: 1.2rem;",
        xsmall: "font-size: 0.938rem; line-height: 1.6rem;",
        small: "font-size: 1rem; line-height: 1.5rem;",
        regular: "font-size: 1.15rem; line-height: 1.6rem;",
        large: "font-size: 1.6rem; line-height: 2.5rem;",
        larger: "font-size: 2.2rem; line-height: 2.8rem;",
        xlarge: "font-size: 2.75rem; line-height: 3.2rem;",
        xxlarge: "font-size: 3.2rem; line-height: 3.65rem;",
    },

    color: {
        black: "#212529",
        darkgray: "#333a45",
        gray: "#888888",
        lightgray: "#f6f6f6",
        white: "#FFFFFF",
        lightblue: "#48a3db",
        altlightblue: "#2d5382",
        blue: "#184783",
        altblue: "#063268",
        darkblue: "#002c60",
        red: "#cc3837",
        lightred: "#f26659",
        orange: "#e75a2f"
    },
    screen: {
        xs: "575px",
        sm: "767px",
        md: "992px",
        lg: "1200px",
    },
    header: {
        height: "172px",
        heightMobile: "95px"
    }

};

export default theme