import React from "react"
import styled from 'styled-components'

export const Wrapper = styled.div`

@media screen and (min-width: calc(${props => props.theme.screen.xs} + 1px)) {
    .squares, .face, .rubik, .scene {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scene {
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  outline: 0;
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100vw;
  height: 100vh;
}

body {
  width: 100%;
  height: 100%;
}

.scene {
  background-color: transparent;
  perspective: 400px;
  // overflow: hidden;
}

.rubik {
  width: 100px;
  height: 100px;
  position: relative;
  transform-style: preserve-3d;
  animation: rotateXYZ 12s infinite linear;
}
.rubik .squares::before, .rubik .squares::after {
  width: 28.3333333333px;
  height: 28.3333333333px;
}
.rubik .face:nth-child(1) .squares::before {
  background-color: #cc3837;
}
.rubik .face:nth-child(1) .squares::after {
  background-color: #cc3837;
}
.rubik .face:nth-child(1) .squares:nth-child(1)::before, .rubik .face:nth-child(1) .squares:nth-child(1)::after {
  left: -47.5px;
  top: -47.5px;
}
.rubik .face:nth-child(1) .squares:nth-child(2)::before, .rubik .face:nth-child(1) .squares:nth-child(2)::after {
  left: -14.1666666667px;
  top: -47.5px;
}
.rubik .face:nth-child(1) .squares:nth-child(3)::before, .rubik .face:nth-child(1) .squares:nth-child(3)::after {
  left: 19.1666666667px;
  top: -47.5px;
}
.rubik .face:nth-child(1) .squares:nth-child(4)::before, .rubik .face:nth-child(1) .squares:nth-child(4)::after {
  left: -47.5px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(1) .squares:nth-child(5)::before, .rubik .face:nth-child(1) .squares:nth-child(5)::after {
  left: -14.1666666667px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(1) .squares:nth-child(6)::before, .rubik .face:nth-child(1) .squares:nth-child(6)::after {
  left: 19.1666666667px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(1) .squares:nth-child(7)::before, .rubik .face:nth-child(1) .squares:nth-child(7)::after {
  left: -47.5px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(1) .squares:nth-child(8)::before, .rubik .face:nth-child(1) .squares:nth-child(8)::after {
  left: -14.1666666667px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(1) .squares:nth-child(9)::before, .rubik .face:nth-child(1) .squares:nth-child(9)::after {
  left: 19.1666666667px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(1) .squares:nth-child(10)::before, .rubik .face:nth-child(1) .squares:nth-child(10)::after {
  left: -47.5px;
  top: 52.5px;
}
.rubik .face:nth-child(2) .squares::before {
  background-color: #184783;
}
.rubik .face:nth-child(2) .squares::after {
  background-color: #184783;
}
.rubik .face:nth-child(2) .squares:nth-child(1)::before, .rubik .face:nth-child(2) .squares:nth-child(1)::after {
  left: -47.5px;
  top: -47.5px;
}
.rubik .face:nth-child(2) .squares:nth-child(2)::before, .rubik .face:nth-child(2) .squares:nth-child(2)::after {
  left: -14.1666666667px;
  top: -47.5px;
}
.rubik .face:nth-child(2) .squares:nth-child(3)::before, .rubik .face:nth-child(2) .squares:nth-child(3)::after {
  left: 19.1666666667px;
  top: -47.5px;
}
.rubik .face:nth-child(2) .squares:nth-child(4)::before, .rubik .face:nth-child(2) .squares:nth-child(4)::after {
  left: -47.5px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(2) .squares:nth-child(5)::before, .rubik .face:nth-child(2) .squares:nth-child(5)::after {
  left: -14.1666666667px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(2) .squares:nth-child(6)::before, .rubik .face:nth-child(2) .squares:nth-child(6)::after {
  left: 19.1666666667px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(2) .squares:nth-child(7)::before, .rubik .face:nth-child(2) .squares:nth-child(7)::after {
  left: -47.5px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(2) .squares:nth-child(8)::before, .rubik .face:nth-child(2) .squares:nth-child(8)::after {
  left: -14.1666666667px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(2) .squares:nth-child(9)::before, .rubik .face:nth-child(2) .squares:nth-child(9)::after {
  left: 19.1666666667px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(2) .squares:nth-child(10)::before, .rubik .face:nth-child(2) .squares:nth-child(10)::after {
  left: -47.5px;
  top: 52.5px;
}
.rubik .face:nth-child(3) .squares::before {
  background-color: #002c60;
}
.rubik .face:nth-child(3) .squares::after {
  background-color: white;
}
.rubik .face:nth-child(3) .squares:nth-child(1)::before, .rubik .face:nth-child(3) .squares:nth-child(1)::after {
  left: -47.5px;
  top: -47.5px;
}
.rubik .face:nth-child(3) .squares:nth-child(2)::before, .rubik .face:nth-child(3) .squares:nth-child(2)::after {
  left: -14.1666666667px;
  top: -47.5px;
}
.rubik .face:nth-child(3) .squares:nth-child(3)::before, .rubik .face:nth-child(3) .squares:nth-child(3)::after {
  left: 19.1666666667px;
  top: -47.5px;
}
.rubik .face:nth-child(3) .squares:nth-child(4)::before, .rubik .face:nth-child(3) .squares:nth-child(4)::after {
  left: -47.5px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(3) .squares:nth-child(5)::before, .rubik .face:nth-child(3) .squares:nth-child(5)::after {
  left: -14.1666666667px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(3) .squares:nth-child(6)::before, .rubik .face:nth-child(3) .squares:nth-child(6)::after {
  left: 19.1666666667px;
  top: -14.1666666667px;
}
.rubik .face:nth-child(3) .squares:nth-child(7)::before, .rubik .face:nth-child(3) .squares:nth-child(7)::after {
  left: -47.5px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(3) .squares:nth-child(8)::before, .rubik .face:nth-child(3) .squares:nth-child(8)::after {
  left: -14.1666666667px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(3) .squares:nth-child(9)::before, .rubik .face:nth-child(3) .squares:nth-child(9)::after {
  left: 19.1666666667px;
  top: 19.1666666667px;
}
.rubik .face:nth-child(3) .squares:nth-child(10)::before, .rubik .face:nth-child(3) .squares:nth-child(10)::after {
  left: -47.5px;
  top: 52.5px;
}
.rubik .face::before, .rubik .face::after {
  width: 100%;
  height: 100%;
}

.face {
  position: absolute;
  transform-style: preserve-3d;
}
.face:nth-child(1) {
  transform: rotate3d(0, 0, 0, 0deg);
}
.face:nth-child(2) {
  transform: rotate3d(0, 1, 0, 90deg);
}
.face:nth-child(3) {
  transform: rotate3d(1, 0, 0, 90deg);
}

.squares {
  transform-style: preserve-3d;
  animation: 6s ease-in-out infinite;
}
.squares, .squares::before, .squares::after {
  position: absolute;
}
.squares::before, .squares::after {
  content: "";
  border-radius: 0;
  animation: 6s ease-in-out infinite;
  backface-visibility: hidden;
}
.squares::before {
  transform: translate3d(0, 0, 50px);
}
.squares::after {
  transform: rotate3d(0, 1, 0, 180deg) translate3d(0, 0, 50px);
}

.face:nth-child(1) .squares:nth-of-type(3n + 1), .face:nth-child(3) .squares:nth-of-type(3n + 1) {
  animation-name: rotationAnimationX;
}
.face:nth-child(1) .squares:nth-of-type(3n + 2), .face:nth-child(3) .squares:nth-of-type(3n + 2) {
  animation-name: rotationAnimation-X;
}
.face:nth-child(1) .squares:nth-of-type(3n + 3), .face:nth-child(3) .squares:nth-of-type(3n + 3) {
  animation-name: rotationAnimationX;
}
.face:nth-child(2) .squares {
  animation-name: rotationAnimationZ;
}

@keyframes rotateXYZ {
  to {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}
@keyframes rotationAnimationX {
  0% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
  12.5% {
    transform: rotate3d(1, 0, 0, 45deg);
  }
  25% {
    transform: rotate3d(1, 0, 0, 90deg);
  }
  37.5% {
    transform: rotate3d(1, 0, 0, 135deg);
  }
  50% {
    transform: rotate3d(1, 0, 0, 180deg);
  }
  62.5% {
    transform: rotate3d(1, 0, 0, 225deg);
  }
  75% {
    transform: rotate3d(1, 0, 0, 270deg);
  }
  87.5% {
    transform: rotate3d(1, 0, 0, 315deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, 360deg);
  }
}
@keyframes rotationAnimation-X {
  0% {
    transform: rotate3d(-1, 0, 0, 0deg);
  }
  12.5% {
    transform: rotate3d(-1, 0, 0, 45deg);
  }
  25% {
    transform: rotate3d(-1, 0, 0, 90deg);
  }
  37.5% {
    transform: rotate3d(-1, 0, 0, 135deg);
  }
  50% {
    transform: rotate3d(-1, 0, 0, 180deg);
  }
  62.5% {
    transform: rotate3d(-1, 0, 0, 225deg);
  }
  75% {
    transform: rotate3d(-1, 0, 0, 270deg);
  }
  87.5% {
    transform: rotate3d(-1, 0, 0, 315deg);
  }
  100% {
    transform: rotate3d(-1, 0, 0, 360deg);
  }
}
@keyframes rotationAnimationY {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  12.5% {
    transform: rotate3d(0, 1, 0, 45deg);
  }
  25% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
  37.5% {
    transform: rotate3d(0, 1, 0, 135deg);
  }
  50% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
  62.5% {
    transform: rotate3d(0, 1, 0, 225deg);
  }
  75% {
    transform: rotate3d(0, 1, 0, 270deg);
  }
  87.5% {
    transform: rotate3d(0, 1, 0, 315deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}
@keyframes rotationAnimation-Y {
  0% {
    transform: rotate3d(0, -1, 0, 0deg);
  }
  12.5% {
    transform: rotate3d(0, -1, 0, 45deg);
  }
  25% {
    transform: rotate3d(0, -1, 0, 90deg);
  }
  37.5% {
    transform: rotate3d(0, -1, 0, 135deg);
  }
  50% {
    transform: rotate3d(0, -1, 0, 180deg);
  }
  62.5% {
    transform: rotate3d(0, -1, 0, 225deg);
  }
  75% {
    transform: rotate3d(0, -1, 0, 270deg);
  }
  87.5% {
    transform: rotate3d(0, -1, 0, 315deg);
  }
  100% {
    transform: rotate3d(0, -1, 0, 360deg);
  }
}
@keyframes rotationAnimationZ {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  12.5% {
    transform: rotate3d(0, 0, 1, 45deg);
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  37.5% {
    transform: rotate3d(0, 0, 1, 135deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
  62.5% {
    transform: rotate3d(0, 0, 1, 225deg);
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
  87.5% {
    transform: rotate3d(0, 0, 1, 315deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
}

`;

const AnimationRubik = ({className}) => {
        return (
            <Wrapper>
                <div className={`scene ${className ? className : ''}`}>
                    <div className="rubik">
                        <div className="face">
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                        </div>
                        <div className="face">
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                        </div>
                        <div className="face">
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                            <div className="squares"></div>
                        </div>
                    </div>
                </div>

            </Wrapper>
        )
};

export default AnimationRubik