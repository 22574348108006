import styled from 'styled-components'
import ReactPaginate from "react-paginate"

import { Button } from "../components/button"

export const MyPaginate = styled(ReactPaginate).attrs({
    // You can redifine classes here, if you want.
    activeClassName: 'active', // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 1rem;
  li a {
    border-radius: 0;
    padding: .5rem 1rem;
    // border: 1px solid #b8b8b8;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: ${props => props.theme.color.blue};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

export const StyledButton = styled(Button)`
    text-transform: uppercase;
`;

export const StyledCard = styled.div`
    width: 100%;
    background-color: ${props => props.theme.color.white};    
    
    box-shadow: 0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04);
    padding: ${props => props.withSections === true ? `0 20px` : `20px`};
    margin-bottom: ${props => props.withSections === true ? `2rem` : `0`};
    
    @media (min-width: 576px) {    
        border-radius: 8px;
    }
    
    & & h1, h2, h3, h4, h5 {
        margin: 0;
    }
    
    & h4 {
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-family: 'Source Sans Pro',Arial,Helvetica,sans-serif;
        font-weight: 500;
        margin: .8rem 0 .8rem 0;
    }
    
    & p {
        margin: 15px 0;
        ${props => props.theme.font_size.small};
    }
    
    & hr {
        margin: 0;
    }
    
    & .img-thumbnail {
        padding: 0.25rem;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        max-width: 100%;
        height: auto;
    }
    
    & .key {
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 600;
        color: ${props => props.theme.color.blue};
    }
    
    & .descrizione {
        padding: 1rem 0;
    }
    
    & button {
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 600;
    }  
`;