import React from "react"
import {StaticImage} from "gatsby-plugin-image"

import Animation from "./animation"
import { StyledContainerFluid } from "../style/hero.style"

const Hero = ({
                  backgroundColor,
                  textColor,
                  className,
                  children,
                  id,
                  style,
                  h1,
                  h1Class,
                  h2,
                  h2Class,
                  image,
                  backgroundImage,
                  animation,
                  contentPadding,
                  gradient
             }) => {
    return (
        <>
            {
                (backgroundImage && !animation) ?
                    // si bg no animazione
                    <StyledContainerFluid id={id} background={backgroundColor} textColor={textColor} gradient={gradient}>
                        <StaticImage src="../assets/images/hero.jpg" quality="100" alt="Zenit Security"
                                     style={{position: "absolute"}} className="background-image"/>
                        <div className={"container"}>
                            <div className={`row ${className ? className : ''}`} style={style}>
                                <div className={`content col-12 py-${contentPadding ? contentPadding : '5'}`}
                                     style={{textAlign: "left"}}>
                                    <h1 className={`pt-0 pt-lg-${contentPadding ? contentPadding : '0'}`}>{h1}</h1>
                                    <h2 className={`pt-0 pb-lg-${contentPadding ? contentPadding : '0'}`}>{h2}</h2>
                                    { children }
                                </div>
                            </div>
                        </div>
                    </StyledContainerFluid>
                    :
                (backgroundImage && animation)  ?
                    // si bg si animazione
                    <StyledContainerFluid id={id} background={backgroundColor} textColor={textColor} gradient={gradient}>
                        <StaticImage src="../assets/images/hero.jpg" quality="100" alt="Zenit Security"
                                     style={{position: "absolute"}} className="background-image"/>
                        <div className={"container"}>
                            <div
                                className={`row ${animation ? 'justify-content-center align-items-center' : ''} ${className ? className : ''}`}
                                style={style}>
                                {animation &&
                                <div
                                    className={`content d-none d-sm-block col-sm-5 col-md-4 col-lg-3 col-xl-2 py-${contentPadding ? contentPadding : '5'}`}
                                    style={{textAlign: "left"}}>
                                    <Animation/>
                                </div>
                                }
                                <div
                                    className={`content ${animation ? 'col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10' : 'col-12'} py-1 py-lg-${contentPadding ? contentPadding : '5'} ${className ? className : ''}`}
                                    style={{textAlign: "left"}}>
                                    <h1 className={`pt-${contentPadding ? contentPadding : '5'} pt-1 px-3`}>{h1}</h1>
                                    <h2 className={`pb-${contentPadding ? contentPadding : '5'} pt-1 px-3`}>{h2}</h2>
                                    { children }
                                </div>
                            </div>
                        </div>
                    </StyledContainerFluid>
                    :
                (!backgroundImage && animation) ?
                    // si animazione e no bg
                    <StyledContainerFluid id={id} background={backgroundColor} textColor={textColor} gradient={gradient}>
                        <div className={`container py-md-5`} style={{maxWidth: "unset"}}>
                            <section className={`row justify-content-center align-items-center ${className ? className : ''}`}
                                     style={style}>
                                <div
                                    className={`${animation && 'd-none d-sm-block col-sm-5 col-md-4 col-lg-3 col-xl-2'} py-${contentPadding ? contentPadding : '5'}`}
                                    style={{textAlign: "left"}}>
                                    <Animation/>
                                </div>
                                <div
                                    className={`col-12 col-sm-7 col-md-8 justify-content-center align-self-center px-3 px-lg-5`}>
                                    <h1 className={`${h1Class ? h1Class + '  pt-4 px-1' : 'pt-4 px-1'}`}
                                        style={{opacity: ".9"}}>
                                        {h1}
                                    </h1>
                                    <h2 className={`${h2Class ? h2Class + ' mx-auto pb-4 px-1' : 'pb-4 px-1'}`}
                                        style={{opacity: ".9"}}>
                                        {h2}
                                    </h2>
                                    { children }
                                </div>
                            </section>
                        </div>
                    </StyledContainerFluid>
                    :
                (image && !backgroundImage && !animation) ?
                    // si image no bg no animazione
                    <StyledContainerFluid id={id} background={backgroundColor} textColor={textColor} gradient={gradient}>
                        <div className={`container ${!image && 'py-md-4'} ${animation && 'py-md-5'}`}
                             style={{maxWidth: "unset"}}>
                            <section className={`row justify-content-center align-items-center ${className ? className : ''}`}
                                     style={style}>
                                <div className={`col-12 px-0 d-lg-none`}>
                                    <StaticImage src="../assets/images/image01.jpg" alt="test"/>
                                </div>
                                <div className={`col-12 col-lg-6 py-${contentPadding ? contentPadding : '5'}`}
                                     style={{textAlign: "left"}}>
                                    <h1 className={`${h1Class ? h1Class + '  pt-4 px-1' : 'pt-4 px-1'}`}
                                        style={{opacity: ".9"}}>
                                        {h1}
                                    </h1>
                                    <h2 className={`${h2Class ? h2Class + ' mx-auto pb-4 px-1' : 'pb-4 px-1'}`}
                                        style={{opacity: ".9"}}>
                                        {h2}
                                    </h2>
                                    { children }
                                </div>
                                <div className={`col-lg-6 justify-content-center align-self-center d-none d-lg-block px-lg-0`}>
                                    <div className={`col-12 px-0`}>
                                        <StaticImage src="../assets/images/image01.jpg" alt="test"/>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </StyledContainerFluid>
                    :
                (!image && !backgroundImage && !animation && gradient) ?
                    // gradient
                    <StyledContainerFluid id={id} background={backgroundColor} textColor={textColor} gradient={gradient}>
                        <div className={"container"}>
                            <div className={`row ${className ? className : ''}`} style={style}>
                                <div className={`content col-12 py-${contentPadding ? contentPadding : '5'}`}
                                     style={{textAlign: "left"}}>
                                    <h1 className={`pt-0 pt-lg-${contentPadding ? contentPadding : '0'}`}>{h1}</h1>
                                    <h2 className={`pb-0 pb-lg-${contentPadding ? contentPadding : '0'}`}>{h2}</h2>
                                    { children }
                                </div>
                            </div>
                        </div>
                        <ul className="bg-bubbles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </StyledContainerFluid>
                    :
                    (!image && !backgroundImage && !animation && !gradient) ?
                        // nulla, solo colore bg
                        <StyledContainerFluid id={id} background={backgroundColor} textColor={textColor} gradient={gradient}>
                            <div className={"container"}>
                                <div className={`row ${className ? className : ''}`} style={style}>
                                    <div className={`content col-12 py-${contentPadding ? contentPadding : '5'}`}
                                         style={{textAlign: "left"}}>
                                        <h1 className={`pt-0 pt-lg-${contentPadding ? contentPadding : '0'}`}>{h1}</h1>
                                        <h2 className={`pb-0 pb-lg-${contentPadding ? contentPadding : '0'}`}>{h2}</h2>
                                        { children }xx
                                    </div>
                                </div>
                            </div>
                        </StyledContainerFluid>
                        :
                null
            }
        </>
    );
};

export {Hero};