import styled, {css} from "styled-components";

export const StyledContainerFixed = styled.div`    
    background-color: ${props => props.background ? props.background : `transparent`};
    color: ${props => props.color ? props.color : `inherit`}; 
    hr {
        border-bottom: 1px solid ${props => props.color ? props.color : `inherit`};
    }
`;

export const StyledContainerFluid = styled.div`    
    background-color: ${props => props.background ? props.background : `transparent`};   
    color: ${props => props.color ? props.color : `inherit`};      
    
    hr {
        border-bottom: 1px solid ${props => props.color ? props.color : `inherit`};
    }
    ${props => props.color && css` 
        hr {
            border-bottom: 1px solid ${props => props.color ? props.color : `inherit`};
        }
    `}
`;