import styled from 'styled-components'

export const Wrapper = styled.aside`

    li a.active {
        color: ${props => props.theme.color.red};
    }
    
    h4 {            
        font-weight: 600;
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: .03rem;
        color: ${props => props.theme.color.blue};
    }
  
     .widget {
        background:${props => props.theme.color.lightgray};
        padding: 30px;
        padding-top: 40px;
        border-radius: 2px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
     }
     
     .widget:not(:last-child) {
        padding-top: 30px;
        margin-bottom: 10px;
        
        p {
            margin: 0;
        }
     }
     
     ul {
         list-style: none;
         padding-left: 0;
         margin-bottom: 0;
     }
     
     li {
        padding: 0;
        position: relative;
    }
    
    ul li ul {
        padding-left: 1rem;
    }
    
    
    
`;