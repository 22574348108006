import React from "react"
import styled from 'styled-components'

export const Wrapper = styled.div`

@media screen and (min-width: calc(${props => props.theme.screen.xs} + 1px)) {
    * {
  box-sizing: border-box;
  transition: 0.35s ease;
}
.abs-center {
  display: false;
  position: initial;
  margin: false;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 88%;
}
body {
  background-color: #002c60;
}

.container {
  width: 30px;
  height: 30px;
  perspective: 6000px;
}
.cube {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(45deg);
}
.cube.cube1 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(-29px);
}
.cube.cube2 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-29px);
}
.cube.cube3 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(0px);
}
.cube.cube4 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(-29px);
}
.cube.cube5 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
}
.cube.cube6 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(29px);
}
.cube.cube7 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(0px);
}
.cube.cube8 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(29px);
}
.cube.cube9 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(29px);
}
.cube.cube10 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-29px);
}
.cube.cube11 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
}
.cube.cube12 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
}
.cube.cube13 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-29px);
}
.cube.cube14 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(0px);
}
.cube.cube15 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(29px);
}
.cube.cube16 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
}
.cube.cube17 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
}
.cube.cube18 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(29px);
}
.cube.cube19 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(-29px);
}
.cube.cube20 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-29px);
}
.cube.cube21 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(0px);
}
.cube.cube22 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(-29px);
}
.cube.cube23 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
}
.cube.cube24 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(29px);
}
.cube.cube25 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(0px);
}
.cube.cube26 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(29px);
}
.cube.cube27 {
  transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(29px);
}
.cube:nth-child(1) {
  -webkit-animation: cubeAnimation1 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation1 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(2) {
  -webkit-animation: cubeAnimation2 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation2 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(3) {
  -webkit-animation: cubeAnimation3 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation3 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(4) {
  -webkit-animation: cubeAnimation4 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation4 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(5) {
  -webkit-animation: cubeAnimation5 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation5 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(6) {
  -webkit-animation: cubeAnimation6 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation6 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(7) {
  -webkit-animation: cubeAnimation7 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation7 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(8) {
  -webkit-animation: cubeAnimation8 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation8 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(9) {
  -webkit-animation: cubeAnimation9 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation9 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(10) {
  -webkit-animation: cubeAnimation10 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation10 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(11) {
  -webkit-animation: cubeAnimation11 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation11 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(12) {
  -webkit-animation: cubeAnimation12 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation12 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(13) {
  -webkit-animation: cubeAnimation13 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation13 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(14) {
  -webkit-animation: cubeAnimation14 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation14 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(15) {
  -webkit-animation: cubeAnimation15 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation15 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(16) {
  -webkit-animation: cubeAnimation16 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation16 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(17) {
  -webkit-animation: cubeAnimation17 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation17 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(18) {
  -webkit-animation: cubeAnimation18 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation18 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(19) {
  -webkit-animation: cubeAnimation19 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation19 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(20) {
  -webkit-animation: cubeAnimation20 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation20 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(21) {
  -webkit-animation: cubeAnimation21 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation21 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(22) {
  -webkit-animation: cubeAnimation22 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation22 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(23) {
  -webkit-animation: cubeAnimation23 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation23 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(24) {
  -webkit-animation: cubeAnimation24 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation24 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(25) {
  -webkit-animation: cubeAnimation25 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation25 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(26) {
  -webkit-animation: cubeAnimation26 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation26 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube:nth-child(27) {
  -webkit-animation: cubeAnimation27 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
          animation: cubeAnimation27 7s cubic-bezier(0.75, 0, 0.2, 1) infinite;
}
.cube div {
  position: absolute;
  width: 100%;
  height: 100%;
}
.cube .front {
  transform: rotateY(0deg) translateZ(15px);
  background-color: #fff;
}
.cube .left {
  transform: rotateY(-90deg) translateZ(15px);
  background-color: #cc3837;
}
.cube .top {
  transform: rotateX(90deg) translateZ(15px);
  background-color: #184783;
}
@-webkit-keyframes cubeAnimation1 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(-29px);
  }
}
@keyframes cubeAnimation1 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation2 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-29px);
  }
}
@keyframes cubeAnimation2 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation3 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(0px);
  }
}
@keyframes cubeAnimation3 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(0px);
  }
}
@-webkit-keyframes cubeAnimation4 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(-29px);
  }
}
@keyframes cubeAnimation4 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation5 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
  }
}
@keyframes cubeAnimation5 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(0px);
  }
}
@-webkit-keyframes cubeAnimation6 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(29px);
  }
}
@keyframes cubeAnimation6 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(29px) translateZ(29px);
  }
}
@-webkit-keyframes cubeAnimation7 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(0px);
  }
}
@keyframes cubeAnimation7 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(0px);
  }
}
@-webkit-keyframes cubeAnimation8 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(29px);
  }
}
@keyframes cubeAnimation8 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(29px) translateZ(29px);
  }
}
@-webkit-keyframes cubeAnimation9 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(29px);
  }
}
@keyframes cubeAnimation9 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(29px) translateZ(29px);
  }
}
@-webkit-keyframes cubeAnimation10 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-29px);
  }
}
@keyframes cubeAnimation10 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation11 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
  }
}
@keyframes cubeAnimation11 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation12 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
  }
}
@keyframes cubeAnimation12 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(0px);
  }
}
@-webkit-keyframes cubeAnimation13 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-29px);
  }
}
@keyframes cubeAnimation13 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation15 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(29px);
  }
}
@keyframes cubeAnimation15 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(0px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(0px) translateZ(29px);
  }
}
@-webkit-keyframes cubeAnimation16 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
  }
}
@keyframes cubeAnimation16 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(0px);
  }
}
@-webkit-keyframes cubeAnimation17 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
  }
}
@keyframes cubeAnimation17 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(0px) translateZ(29px);
  }
}
@-webkit-keyframes cubeAnimation18 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(29px);
  }
}
@keyframes cubeAnimation18 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(0px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(0px) translateZ(29px);
  }
}
@-webkit-keyframes cubeAnimation19 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(-29px);
  }
}
@keyframes cubeAnimation19 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation20 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-29px);
  }
}
@keyframes cubeAnimation20 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation21 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(0px);
  }
}
@keyframes cubeAnimation21 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(0px);
  }
}
@-webkit-keyframes cubeAnimation22 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(-29px);
  }
}
@keyframes cubeAnimation22 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(-29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(-60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-29px) translateZ(-60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(-60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(-29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-50.25px) translateZ(-29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(-29px);
  }
}
@-webkit-keyframes cubeAnimation23 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
  }
}
@keyframes cubeAnimation23 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(0px);
  }
}
@-webkit-keyframes cubeAnimation24 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(29px);
  }
}
@keyframes cubeAnimation24 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(60px) translateY(-50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(29px) translateY(-29px) translateZ(29px);
  }
}
@-webkit-keyframes cubeAnimation25 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(0px);
  }
}
@keyframes cubeAnimation25 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(0px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(0px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-29px) translateZ(0px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(0px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(0px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-50.25px) translateZ(0px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(0px);
  }
}
@-webkit-keyframes cubeAnimation26 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(29px);
  }
}
@keyframes cubeAnimation26 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(0px) translateY(-29px) translateZ(29px);
  }
}
@-webkit-keyframes cubeAnimation27 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(29px);
  }
}
@keyframes cubeAnimation27 {
  0% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(29px);
  }
  16.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(60px);
  }
  33.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-29px) translateZ(60px);
  }
  50% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(60px);
  }
  66.666% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-60px) translateY(-50.25px) translateZ(29px);
  }
  83.333% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-50.25px) translateZ(29px);
  }
  100% {
    transform: rotateX(-30deg) rotateY(45deg) translateX(-29px) translateY(-29px) translateZ(29px);
  }
}
}

`;

const Animation = ({className}) => {
        return (
            <Wrapper>
                <section className={`abs-center container ${className ? className : ''}`}>
                    <div className="cube cube1">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube2">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube3">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube4">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube5">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube6">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube7">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube8">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube9">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube10">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube11">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube12">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube13">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube14">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube15">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube16">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube17">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube18">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube19">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube20">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube21">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube22">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube23">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube24">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube25">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube26">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                    <div className="cube cube27">
                        <div className="front"></div>
                        <div className="left"></div>
                        <div className="top"></div>
                    </div>
                </section>
            </Wrapper>
        )
};

export default Animation