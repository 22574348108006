import React from "react"

import { useFetch } from "../utils/useFetch"
import { jsonConfigSito } from "../utils/jsonData"
import { StyledFooter } from "../style/footer.style"
import {FullWidth as SectionFullWidth} from "./splitsections";
import Anchor from "./anchor";



const Footer = ({id, background, color, style, className, children}) => {

    return (
        <StyledFooter id={id} background={background} color={color} style={style}>
            <div className={"container"}>
                <section className={`row ${className ? className : ''}`}>
                    {children}
                    <SectionFullWidth>
                        <Copyright from="2022" />
                        <PIva />
                        <Anchor href="https://xxx.pdf" title={"Scarica il PDF con la Privacy Policy"}>Privacy e Cookie Policy</Anchor>
                        <hr />
                        <p className="mb-1">Progettazione e sviluppo <Anchor href="https://www.zenitsecurity.com" title="Vai al sito di Zenit Security">Zenit Security</Anchor></p>
                        <Patamu />
                    </SectionFullWidth>
                </section>
            </div>
        </StyledFooter>
    )
};

const Copyright = ({from}) => {
    let currentYear = new Date().getFullYear();
    return (
        <>
            {
                (JSON.stringify(currentYear) !== from) ?
                    <>
                        &copy; {from} - {new Date().getFullYear()}
                    </>
                    :
                    <>
                        &copy; {from}
                    </>
            }
        </>
    )
};

const PIva = () => {
    const { data } = useFetch(jsonConfigSito, {});
    const { footer: { nome, indirizzo, partitaIVA } = { nome: "", indirizzo: "", partitaIVA: "" } } = data;
    return (
        <>
            {(nome !== undefined && nome !== "") ?
                <span className="m-0 mx-1 font-weight-600 d-block d-md-inline-block"> <span className="d-none d-md-inline-block">|</span> {nome}</span>
                :
                ""
            }
            {(indirizzo !== undefined && indirizzo !== "") ?
                <span className="m-0 d-block d-md-inline-block">{indirizzo}</span>
                :
                ""
            }
            {(partitaIVA !== undefined && partitaIVA !== "") ?
                <span className="mb-1 mx-1 font-weight-600 d-block d-md-inline-block">
                <span className="d-none d-md-inline-block">|</span> P.IVA: {partitaIVA} <span className="d-none d-md-inline-block">|</span>
            </span>
                :
                ""
            }
        </>
    );
}

const Patamu = () => {
    const { data } = useFetch(jsonConfigSito, {});
    const { footer: { patamu } = { patamu: "" } } = data;
    return (
        <>
            {(patamu !== undefined && patamu !== "") ?
                <>
                    <div>
                        Tutti i contenuti del sito sono tutelati dal plagio su <b>patamu.com</b> con numero deposito <b>{patamu}</b>
                    </div>
                </>
                :
                ""
            }
        </>
    );

};

export {Footer, Copyright, PIva, Patamu}