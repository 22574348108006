import React from "react"

import { StyledContainerFixed, StyledContainerFluid } from "../style/container.style"

const Fixed = ({ background, color, className, children, id, style }) => (

    <StyledContainerFixed id={id} background={background} color={color} className={`container`} style={style}>
        <section className={`row ${className ? className : ''}`}>
            {children}
        </section>
    </StyledContainerFixed>

);

const Fluid = ({ background, color, className, children, id, style }) => (

    <StyledContainerFluid id={id} background={background} color={color} style={style}>
        <div className={"container"}>
            <section className={`row ${className ? className : ''}`}>
                {children}
            </section>
        </div>
    </StyledContainerFluid>

);

const FullWidth = ({ background, color, className, children, id, style }) => (

    <StyledContainerFluid id={id} background={background} color={color} style={style}>
        <div className={"container-fluid"}>
            <section className={`row ${className ? className : ''}`}>
                {children}
            </section>
        </div>
    </StyledContainerFluid>

);

export {Fixed, Fluid, FullWidth};